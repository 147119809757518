/**
 * Metadata about a file (e.g., a file selected via a file picker)
 */
export default class FileDetails {
  /**
   * An OPTIONAL file object associated with this file. Sometimes we do not have
   * access to the original file object (e.g., when re-inflating a form with
   * previously-uploaded files), so you cannot assume the file object will
   * always exist.
   */
  file?: File

  name: string
  sizeBytes: number
  mimeType: string

  constructor (data: Pick<FileDetails,
    'file' |
    'name' |
    'sizeBytes' |
    'mimeType'
  >) {
    this.file = data.file
    this.name = data.name
    this.sizeBytes = data.sizeBytes
    this.mimeType = data.mimeType
  }
}
