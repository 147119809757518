<template>
  <eWallet
    v-bind="eWalletProps"
    ref="ewallet"
    @phone-update="phone => $emit('phoneUpdate', phone)"
    @contact-preference-update="preference => $emit('contactPreferenceUpdate', preference)"
    @tender-type-changed="type => $emit('tenderTypeChanged', type)"
    @tender-form-type-changed="type => $emit('tenderFormTypeChanged', type)"
    @validation-error="errors => $emit('validationError', errors)"
    @selected-data-changed="data => $emit('selectedDataChanged', data)"
    @extra-fields-update="data => $emit('extraFieldsUpdate', data)"
  >
    <template #additional-checkboxes>
      <slot name="additional-checkboxes" />
    </template>
  </eWallet>
</template>

<script>
// This just customizes via delegation the main e-Wallet.
import { mapGetters, mapState } from 'vuex'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import ModuleLoadError from '@grantstreet/psc-vue/components/ModuleLoadError.vue'
import { mapConfigState } from '@grantstreet/psc-config'
import { user } from '@grantstreet/login'

export default {
  emits: [
    'phoneUpdate',
    'contactPreferenceUpdate',
    'tenderTypeChanged',
    'tenderFormTypeChanged',
    'validationError',
    'selectedDataChanged',
    'extraFieldsUpdate',
    'submit',
  ],
  components: {
    // Code-split the eWallet component so that it is only loaded on pages that
    // need it
    eWallet: () => ({
      component: import('@grantstreet/e-wallet-vue/src/components/eWallet.vue'),
      loading: LoadingBars,
      error: ModuleLoadError,
    }),
  },

  props: {
    alwaysGetWarehouseToken: {
      type: Boolean,
      default: true,
    },
    alwaysGetWarehouseTokenForBanks: {
      type: Boolean,
      default: true,
    },
    alwaysSaveTender: {
      type: Boolean,
      default: true,
    },
    autoPaySelected: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    defaultEmail: {
      type: String,
      default: '',
    },
    defaultPhone: {
      type: String,
      default: '',
    },
    defaultContactPreference: {
      type: String,
      default: 'email',
    },
    defaultBillingAddress: {
      type: Object,
      default: () => ({}),
    },
    showSaveCheckbox: {
      type: Boolean,
      default: true,
    },
    convenienceFees: {
      type: Object,
      default: () => ({}),
    },
    translatedFeeKeys: {
      type: Array,
      default: () => ([]),
    },
    scrollContainer: {
      type: String,
      default: null,
    },
    view: {
      type: String,
      default: null,
    },
    // May be a tender id or an ewallet token (uuid)
    initialTenderId: {
      type: [String, Number],
      default: null,
    },
    onlyAnonymousTenders: {
      type: Boolean,
      default: false,
    },
    adminFilling: {
      type: Boolean,
      default: false,
    },

    paypalConfig: {
      type: Object,
      default: null,
    },
    applePayConfig: {
      type: Object,
      default: null,
    },
    forceSaveMethod: {
      type: Boolean,
      default: false,
    },
    firstMethod: {
      type: String,
      default: '',
    },
    hideTenders: {
      type: Array,
      default: () => [],
    },
    forceMultiUseTender: {
      type: Boolean,
      default: false,
    },
    /**
     * restrictedPayableMethods are all the payment methods that should
     * not be allowed when showing e-wallet. The site's disabled payment
     * methods will be automatically added to this list.
     */
    restrictedPayableMethods: {
      type: Array,
      default: () => [],
    },
    disabledTendersMessage: {
      type: String,
      default: '',
    },
    disabledTendersTooltips: {
      type: Object,
      default: () => ({}),
    },
    isTwoPassAuthorization: {
      type: Boolean,
      default: false,
    },
    enableSms: {
      type: Boolean,
      default: true,
    },
    noSubmitButton: {
      type: Boolean,
      default: false,
    },
    beforeSubmit: {
      type: Function,
      default: null,
    },
    getUpdatedFee: {
      type: Function,
      default: null,
    },
    submitCallback: {
      type: Function,
      default: null,
    },
    googlePayConfig: {
      type: Object,
      default: null,
    },
    cartSubtotal: {
      type: [String, Number],
      default: '',
    },
    clientTitle: {
      type: String,
      default: '',
    },
    submitButtonText: {
      type: String,
      default: null,
    },
    cartHasDelayedPayments: {
      type: Boolean,
      default: false,
    },
    isMixedRexCart: {
      type: Boolean,
      default: false,
    },
    hideFeeMessage: {
      type: Boolean,
      default: false,
    },
    useCostLinkedPricing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    eWalletProps () {
      const props = {
        key: this.eWalletKey,
        enabled: this.enabled && !this.cart.expired,
        'show-one-time-use-disclosure': false,
        // Keep in sync with Checkout.vue's EWalletProgressButton
        'show-extra-fields': true,
        'extra-fields': {
          email: {
            required: true,
            initialValue: this.email,
            showForSavedTenders: true,
            disableIfLoggedIn: true,
          },
          phone: {
            required: true,
            initialValue: this.phone,
            showForSavedTenders: true,
          },
          contactPreference: {
            required: true,
            initialValue: this.contactPreference,
            showForSavedTenders: true,
          },
        },
      }

      const {
        preExpandCardForm,
        debitSendingIds,
        showFeeCheckbox,
        disabledFields,
        needsBankBillingAddress,
        preFillAddressLabel,
        useExpiringTenders,
      } = this.config.eWallet

      let feeName = this.config.eWallet.feeName
      // This will default to a name set by payhub if available. This is
      // currently set in loadConfigI18n
      if (!feeName && this.$te('fee.name')) {
        feeName = this.$t('fee.name')
      }

      const flag = `fee-name.${this.config.client}-${this.config.site}`
      if (this.flags.hasOwnProperty(flag)) {
        feeName = this.$t('fee.name')
      }

      if (typeof this.alwaysGetWarehouseToken !== 'undefined') {
        props['always-get-warehouse-token'] = this.alwaysGetWarehouseToken
      }
      if (typeof this.alwaysGetWarehouseTokenForBanks !== 'undefined') {
        props['always-get-warehouse-token-for-banks'] = this.alwaysGetWarehouseTokenForBanks
      }
      if (typeof this.alwaysSaveTender !== 'undefined') {
        props['always-save-tender'] = this.alwaysSaveTender
      }
      if (this.dataVaultToken) {
        props['data-vault-token'] = this.dataVaultToken
      }
      if (this.view) {
        props.view = this.view
      }
      if (this.siteEnabledPaymentMethods) {
        props['allowed-methods'] = this.siteEnabledPaymentMethods
        props['restricted-methods'] = props['allowed-methods'].filter(method =>
          this.restrictedPayableMethods?.includes(method) ||
          !this.allowedPayableMethods?.includes(method),
        )
        // Restrict google pay and apple pay if cards are restricted
        if (props['restricted-methods'].includes('card')) {
          props['restricted-methods'].push('google')
          props['restricted-methods'].push('apple')
        }
      }
      else {
        if (this.restrictedPayableMethods) {
          props['restricted-methods'] = this.restrictedPayableMethods
        }
        // TODO: Looks to me like this is straight up broken since
        // implementation in PSC-7000. If this.siteEnabledPaymentMethods is
        // falsey then the allowedPayableMethods getter should throw.
        if (this.allowedPayableMethods) {
          props['allowed-methods'] = this.allowedPayableMethods
        }
      }
      if (this.autoPaySelected) {
        props['auto-pay-selected'] = this.autoPaySelected
      }
      if (this.convenienceFees) {
        props['convenience-fees'] = this.convenienceFees
      }
      if (this.translatedFeeKeys) {
        props['translated-fee-keys'] = this.translatedFeeKeys
      }
      if (typeof this.showSaveCheckbox !== 'undefined') {
        props['show-save-checkbox'] = this.showSaveCheckbox
      }
      if (typeof preExpandCardForm !== 'undefined') {
        props['pre-expand-card-form'] = preExpandCardForm
      }
      if (debitSendingIds && debitSendingIds.length) {
        props['debit-sending-ids'] = debitSendingIds
      }
      // Remove after A/B test
      if (this.flags['use-alt-filter-advisory-a-b']) {
        props['use-alt-debit-filter-advisory'] = true
      }
      if (showFeeCheckbox) {
        props['show-fee-checkbox'] = showFeeCheckbox
      }
      if (disabledFields) {
        props['disabled-fields'] = disabledFields
      }
      if (typeof needsBankBillingAddress !== 'undefined') {
        props['needs-billing-address'] = needsBankBillingAddress
      }
      if (this.defaultBillingAddress) {
        props['default-billing-address'] = this.billing
      }
      if (typeof preFillAddressLabel !== 'undefined') {
        props['pre-fill-address-label'] = preFillAddressLabel
      }
      if (typeof useExpiringTenders !== 'undefined') {
        props['use-expiring-tenders'] = useExpiringTenders
      }
      if (feeName) {
        props['fee-name'] = feeName
      }
      if (this.scrollContainer) {
        props['scroll-container'] = this.scrollContainer
      }
      if (this.initialTenderId) {
        props['initial-tender-id'] = this.initialTenderId
      }
      if (typeof this.onlyAnonymousTenders !== 'undefined') {
        props['only-anonymous-tenders'] = this.onlyAnonymousTenders
      }
      if (typeof this.adminFilling !== 'undefined') {
        props['admin-filling'] = this.adminFilling
      }
      if (this.paypalConfig) {
        props['paypal-config'] = this.paypalConfig
      }
      if (this.applePayConfig) {
        props['apple-pay-config'] = this.applePayConfig
      }
      if (this.forceSaveMethod) {
        props['force-save-method'] = this.forceSaveMethod
      }
      if (this.firstMethodProp) {
        props['first-method'] = this.firstMethodProp
      }
      if (this.hideTenders) {
        props['hide-tenders'] = this.hideTenders
      }
      if (this.disabledTendersMessage) {
        props['disabled-tenders-message'] = this.disabledTendersMessage
      }
      if (this.disabledTendersTooltips) {
        props['disabled-tenders-tooltips'] = this.disabledTendersTooltips
      }
      if (this.isTwoPassAuthorization) {
        props['is-two-pass-authorization'] = this.isTwoPassAuthorization
      }
      if (this.disabledCardBrands) {
        props['disabled-card-brands'] = this.disabledCardBrands
      }
      if (this.noSubmitButton) {
        props['no-submit-button'] = this.noSubmitButton
      }
      if (this.beforeSubmit) {
        props['before-submit'] = this.beforeSubmit
      }
      if (this.getUpdatedFee) {
        props['get-updated-fee'] = this.getUpdatedFee
      }
      if (this.googlePayConfig) {
        props['google-pay-config'] = this.googlePayConfig
      }
      if (this.cartSubtotal) {
        props['cart-subtotal'] = this.cartSubtotal
      }
      if (this.clientTitle) {
        props['client-title'] = this.clientTitle
      }
      if (this.submitButtonText) {
        props['submit-button-text'] = this.submitButtonText
      }
      if (this.cartHasDelayedPayments) {
        props['cart-has-delayed-payments'] = this.cartHasDelayedPayments
      }
      if (this.isMixedRexCart) {
        props['is-mixed-rex-cart'] = this.isMixedRexCart
      }
      if (this.hideFeeMessage) {
        props['hide-fee-message'] = this.hideFeeMessage
      }
      if (this.useCostLinkedPricing) {
        props['use-cost-linked-pricing'] = this.useCostLinkedPricing
      }
      if (this.forceMultiUseTender) {
        props['force-multi-use-tender'] = this.forceMultiUseTender
      }
      // If no callback is provided then emit an event
      props['submit-callback'] = this.submitCallback || (data => this.$emit('submit', data))
      props['enable-sms'] = this.config.messaging.enableSMSMessaging
      props['should-display-ewallet'] = this.allItemsConfirmed
      props['disable-ewallet-message'] = this.disableEwalletMessage

      return props
    },

    billing () {
      const {
        address1,
        address2,
        city,
        state,
        postal_code: postalCode,
        country = 'US',
        userName,
      } = this.defaultBillingAddress
      return {
        address1,
        address2,
        city,
        state,
        postalCode,
        country,
        userName,
      }
    },

    email () {
      return this.defaultEmail || this.defaultBillingAddress.email || user.email
    },

    phone () {
      return this.defaultPhone || this.defaultBillingAddress.phone || user.phone
    },

    contactPreference () {
      return user.contactPreference || this.defaultContactPreference
    },

    firstMethodProp () {
      if (this.firstMethod) return this.firstMethod
      if (this.config.eWallet.firstMethod) return this.config.eWallet.firstMethod
      return ''
    },

    disableEwalletMessage () {
      return 'cart.ewallet.requires_confirmation_checkbox'
    },

    ...mapState('PayHub', [
      'dataVaultToken',
      'encodedJWT',
      'eWalletKey',
    ]),

    ...mapGetters('Cart', [
      'cart',
      'allowedPayableMethods',
      'siteEnabledPaymentMethods',
      'allItemsConfirmed',
      'disabledCardBrands',
    ]),

    ...mapConfigState([
      'config',
      'flags',
    ]),
  },

  methods: {
    submit () {
      return this.$refs.ewallet.submit()
    },

    validate () {
      return this.$refs.ewallet.validate()
    },

    setCardError (error) {
      this.$refs.ewallet.setCardError(error)
    },

    setBankError (error) {
      this.$refs.ewallet.setBankError(error)
    },

    setApplePayError (error) {
      this.$refs.ewallet.setApplePayError(error)
    },

    clearApplePayError () {
      this.$refs.ewallet.clearApplePayError()
    },

    setGooglePayError (error) {
      this.$refs.ewallet.setGooglePayError(error)
    },

    clearGooglePayError () {
      this.$refs.ewallet.clearGooglePayError()
    },

    setPaypalError (error) {
      this.$refs.ewallet.setPaypalError(error)
    },

    clearPaypalError () {
      this.$refs.ewallet.clearPaypalError()
    },

    clearFeeCheckbox () {
      this.$refs.ewallet.clearFeeCheckbox()
    },

    resetTenderForms () {
      this.$refs.ewallet.resetTenderForms()
    },
  },
}
</script>
