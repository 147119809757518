/**
 * @function getUsStateFromZip
 * Returns a US two-letter state code if the zip code matches the format for
 * any, otherwise undefined.
 * @param  {string} zip       - The zip code to parse.
 * @return {string|undefined} - US state code or undefined
 */
export const getUsStateFromZip = zip => {
  // Must be exactly 5 digits
  if (typeof zip !== 'string' || !/^\d{5}$/.test(zip)) {
    return
  }

  // Ensure we don't parse strings starting with 0 as octal values
  zip = parseInt(zip, 10)

  if (zip >= 35_000 && zip <= 36_999) {
    return 'AL'
  }
  if (zip >= 99_500 && zip <= 99_999) {
    return 'AK'
  }
  if (zip >= 85_000 && zip <= 86_999) {
    return 'AZ'
  }
  if (zip >= 71_600 && zip <= 72_999) {
    return 'AR'
  }
  if (zip >= 90_000 && zip <= 96_699) {
    return 'CA'
  }
  if (zip >= 80_000 && zip <= 81_999) {
    return 'CO'
  }
  if ((zip >= 6000 && zip <= 6389) || (zip >= 6391 && zip <= 6999)) {
    return 'CT'
  }
  if (zip >= 19_700 && zip <= 19_999) {
    return 'DE'
  }
  if (zip >= 32_000 && zip <= 34_999) {
    return 'FL'
  }
  if ((zip >= 30_000 && zip <= 31_999) || (zip >= 39_800 && zip <= 39_999)) {
    return 'GA'
  }
  if (zip >= 96_700 && zip <= 96_999) {
    return 'HI'
  }
  if (zip >= 83_200 && zip <= 83_999) {
    return 'ID'
  }
  if (zip >= 60_000 && zip <= 62_999) {
    return 'IL'
  }
  if (zip >= 46_000 && zip <= 47_999) {
    return 'IN'
  }
  if (zip >= 50_000 && zip <= 52_999) {
    return 'IA'
  }
  if (zip >= 66_000 && zip <= 67_999) {
    return 'KS'
  }
  if (zip >= 40_000 && zip <= 42_999) {
    return 'KY'
  }
  if (zip >= 70_000 && zip <= 71_599) {
    return 'LA'
  }
  if (zip >= 3900 && zip <= 4999) {
    return 'ME'
  }
  if (zip >= 20_600 && zip <= 21_999) {
    return 'MD'
  }
  if ((zip >= 1000 && zip <= 2799) || (zip === 5501) || (zip === 5544)) {
    return 'MA'
  }
  if (zip >= 48_000 && zip <= 49_999) {
    return 'MI'
  }
  if (zip >= 55_000 && zip <= 56_899) {
    return 'MN'
  }
  if (zip >= 38_600 && zip <= 39_999) {
    return 'MS'
  }
  if (zip >= 63_000 && zip <= 65_999) {
    return 'MO'
  }
  if (zip >= 59_000 && zip <= 59_999) {
    return 'MT'
  }
  if (zip >= 27_000 && zip <= 28_999) {
    return 'NC'
  }
  if (zip >= 58_000 && zip <= 58_999) {
    return 'ND'
  }
  if (zip >= 68_000 && zip <= 69_999) {
    return 'NE'
  }
  if (zip >= 88_900 && zip <= 89_999) {
    return 'NV'
  }
  if (zip >= 3000 && zip <= 3899) {
    return 'NH'
  }
  if (zip >= 7000 && zip <= 8999) {
    return 'NJ'
  }
  if (zip >= 87_000 && zip <= 88_499) {
    return 'NM'
  }
  if ((zip >= 10_000 && zip <= 14_999) || (zip === 6390) || (zip === 501) || (zip === 544)) {
    return 'NY'
  }
  if (zip >= 43_000 && zip <= 45_999) {
    return 'OH'
  }
  if ((zip >= 73_000 && zip <= 73_199) || (zip >= 73_400 && zip <= 74_999)) {
    return 'OK'
  }
  if (zip >= 97_000 && zip <= 97_999) {
    return 'OR'
  }
  if (zip >= 15_000 && zip <= 19_699) {
    return 'PA'
  }
  if (zip >= 300 && zip <= 999) {
    return 'PR'
  }
  if (zip >= 2800 && zip <= 2999) {
    return 'RI'
  }
  if (zip >= 29_000 && zip <= 29_999) {
    return 'SC'
  }
  if (zip >= 57_000 && zip <= 57_999) {
    return 'SD'
  }
  if (zip >= 37_000 && zip <= 38_599) {
    return 'TN'
  }
  if ((zip >= 75_000 && zip <= 79_999) || (zip >= 73_301 && zip <= 73_399) || (zip >= 88_500 && zip <= 88_599)) {
    return 'TX'
  }
  if (zip >= 84_000 && zip <= 84_999) {
    return 'UT'
  }
  if (zip >= 5000 && zip <= 5999) {
    return 'VT'
  }
  if ((zip >= 20_100 && zip <= 20_199) || (zip >= 22_000 && zip <= 24_699) || (zip === 20_598)) {
    return 'VA'
  }
  if ((zip >= 20_000 && zip <= 20_099) || (zip >= 20_200 && zip <= 20_599) || (zip >= 56_900 && zip <= 56_999)) {
    return 'DC'
  }
  if (zip >= 98_000 && zip <= 99_499) {
    return 'WA'
  }
  if (zip >= 24_700 && zip <= 26_999) {
    return 'WV'
  }
  if (zip >= 53_000 && zip <= 54_999) {
    return 'WI'
  }
  if (zip >= 82_000 && zip <= 83_199) {
    return 'WY'
  }
}
