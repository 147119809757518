import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment'
// eslint-disable-next-line no-restricted-imports -- only importing a type
import { SentryMessageFunction } from '@grantstreet/sentry'

export default class UploadApiClass extends GenericApiClient {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- API client
     does not support types yet */
  constructor (opts: any) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = () => process.env?.GSG_UPLOADS_SERVICE || `${baseGovHubUrl}/svc/uploads`
  }

  ping () {
    return this.get('/v1/ping')
  }

  initiateUpload (sourceTag: string, retainUntil?: string) {
    return this.post('/v1/uploads', { sourceTag, retainUntil })
  }

  makeUpload (uri: string, file: File) {
    const genericApi = new NoBaseUrlApiClass(this.exceptionLogger)

    const formData = new FormData()
    formData.append('filename', file)

    return genericApi.post(uri, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}

/**
 * An API that does not have a base URL so we can POST to the URI returned from
 * the upload service without a prefix.
 */
class NoBaseUrlApiClass extends GenericApiClient {
  constructor (exceptionLogger: SentryMessageFunction) {
    super({ exceptionLogger })
  }
}
