import GenericApiClient from '@grantstreet/api-client'
import Schedule from './models/Schedule.js'
import { toLocalDatestring, getTimelessDateFromString } from '@grantstreet/psc-js/utils/date.js'
import { baseGovHubUrl } from '@grantstreet/psc-environment'
import { firstToLowerCase } from '@grantstreet/psc-js/utils/cases.js'
import { frequencies, specs } from './models/Frequencies.js'

const specKeyRegex = /^(OneTime|Recurring)\w*?(BeforeDue|YearlyOnDate|OnDate|EveryXWeeks)$/

const buildFrequency = ({ type, date, startDate = date, endDate, daysBeforeDue, interval, dayOfWeek }, timeZone) => {
  const match = type.match(specKeyRegex)
  if (!match || !match[1] || !match[2]) {
    throw new Error(`Unrecognized frequency type: ${type}`)
  }

  let [, frequencyKey, specKey] = match
  frequencyKey = firstToLowerCase(frequencyKey)
  specKey = firstToLowerCase(specKey)

  return new frequencies[frequencyKey]({
    spec: new specs[specKey]({
      daysBeforeDue,
      interval,
      dayOfWeek,
      startDate: toLocalDatestring(startDate),
      endDate: endDate ? toLocalDatestring(endDate) : endDate,
    }),
  })
}

// Helper function to turn our frontend schedule model into the backend model
const getBody = (schedule) => {
  const body = {
    payablePath: schedule.payable?.isYearlyAutopayEligible
      ? schedule.payable.savePath
      : schedule.payable.raw.path,

    user: {
      id: schedule.user.sub,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      email: schedule.user.email,
      name: schedule.user.name,
      phone: schedule.user.phone,
      language: schedule.user.language || 'en',
    },

    frequencyRule: createFrequencyRule(schedule.frequency),

    suspensionStart: schedule.pause.startDate ? Schedule.formatApiDate(schedule.pause.startDate) : null,
    suspensionEnd: schedule.pause.endDate ? Schedule.formatApiDate(schedule.pause.endDate) : null,

    agreement: schedule.agreement,
    client: schedule.client,
    site: schedule.site,
    adminOnly: schedule.adminOnly,
  }

  body.constantAmount = schedule.amount.value || null

  // We optionally add tender information for when an admin is modifying an
  // existing schedule without changing tender info
  if (schedule.tender.ewalletToken) {
    body.tender = {
      // Note: the lookup token, not the actual ID
      token: schedule.tender.ewalletToken,
      description: schedule.tender.description,
      type: schedule.tender.type,
      // eslint-disable-next-line camelcase
      last_digits: schedule.tender.lastDigits,
      // eslint-disable-next-line camelcase
      bank_account_type: schedule.tender.bankAccountType,
      // eslint-disable-next-line camelcase
      card_brand: schedule.tender.cardBrand,
    }
  }

  return body
}

const createFrequencyRule = ({
  value,
  spec: {
    value: specValue,
    startDate,
    endDate,
    daysBeforeDue,
    interval,
    dayOfWeek,
  } }) => {
  let dateKey = 'startDate'
  let type
  if (value === 'oneTime') {
    if (specValue === 'beforeDue') {
      type = 'OneTimeBeforeDue'
    }
    else if (specValue === 'onDate') {
      type = 'OneTimeOnDate'
      dateKey = 'date'
    }
  }
  else if (value === 'recurring') {
    if (specValue === 'beforeDue') {
      type = 'RecurringBeforeDue'
    }
    // XXX: Will need to account for different recurrence_label s later
    else if (specValue === 'onDate') {
      type = 'RecurringMonthlyOnDate'
    }
    else if (specValue === 'yearlyOnDate') {
      type = 'RecurringYearlyOnDate'
    }
    else if (specValue === 'everyXWeeks') {
      type = 'RecurringEveryXWeeks'
    }
  }
  if (!type) {
    throw new Error(`Unrecognized frequency type: ${value}`)
  }

  const data = {
    type,
    [dateKey]: Schedule.formatApiDate(startDate || new Date()),
  }

  if (daysBeforeDue) {
    data.daysBeforeDue = Number(daysBeforeDue)
  }
  if (endDate) {
    data.endDate = Schedule.formatApiDate(endDate)
  }
  if (interval) {
    data.interval = Number(interval)
  }
  if (dayOfWeek) {
    data.dayOfWeek = dayOfWeek
  }

  return data
}

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = process.env?.GSG_SCHEDPAY_SERVICE || `${baseGovHubUrl}/svc/schedpay`

    this.searchByMap = {
      email: 'userEmail',
      username: 'userName',
      path: 'payablesPaths',
      nextPayment: 'nextRun',
    }
  }

  ping () {
    return this.get('/v2/ping')
  }

  // returns a promise of all the recent runs of this schedule
  async getRuns (schedId, future = true) {
    const response = await this.get('/v2/plans/' + schedId + '/runs' + (future ? '?includeFuture=1' : ''))
    return response.data.map(this.inflateRun)
  }

  // returns a promise of a on run of this schedule
  getRun (schedId, runId) {
    const { data } = this.get('/v2/plans/' + schedId + '/runs/' + runId)
    return this.inflateRun(data)
  }

  // inflateRun processes run objects from the backend into a more
  // useful form for the frontend.
  inflateRun (run) {
    run.date = run.date ? toLocalDatestring(run.date) : run.date
    return run
  }

  // this also updates the passed-in schedule with the schedule id provided by the server
  async addSchedule (schedule, adminUserJwt) {
    const requestConfig = {}
    if (adminUserJwt) {
      requestConfig.headers = { 'X-Remote-User': adminUserJwt }
    }
    const response = await this.postPlanRaw(getBody(schedule), requestConfig)
    schedule.id = response.data.id
  }

  // A raw version of addSchedule that expects API fields instead of a full
  // schedule object
  async postPlanRaw (data, requestConfig = {}) {
    return await this.post('/v2/plans', data, requestConfig)
  }

  updateSchedule (schedule, adminUserJwt) {
    const requestConfig = {}
    if (adminUserJwt) {
      requestConfig.headers = { 'X-Remote-User': adminUserJwt }
    }
    return this.patch('/v2/plans/' + schedule.id, getBody(schedule), requestConfig)
  }

  // This doesn't actually 'remove' a schedule on the backend, just sets it to
  // inactive so it'll never be returned again
  deleteSchedule (schedule, adminUserJwt) {
    const requestConfig = {}
    if (adminUserJwt) {
      requestConfig.headers = { 'X-Remote-User': adminUserJwt }
    }
    return this.patch('/v2/plans/' + schedule.id, { active: false }, requestConfig)
  }

  // runSchedule will run a schedule immediately and return the run
  // information (if it was able to run).
  async runSchedule (schedule, adminUserJwt = '') {
    const requestConfig = {}
    if (adminUserJwt) {
      requestConfig.headers = { 'X-Remote-User': adminUserJwt }
    }
    return this.post(`/v2/plans/${schedule.id}/runs`, null, requestConfig)
      .then((res) => {
        return this.inflateRun(res.data)
      })
  }

  // Gets a list of schedules for the current user or based on search parameters
  // passed in `data`. If `data` is undefined, this will not pass any "by"
  // fields to Schep and Schep will look up plans for the user in the
  // Authorization JWT. If `data` keys/values are passed, they will be used to
  // search for plans (this is used for schep admin).
  //
  // This does not return schedules that have no corresponding payable (e.g.,
  // because the payable was removed from Paystore and the plan has an
  // end_reason of "EndAutomaticPayableRemoved"). See PSC-10003.
  async getSchedulesBy ({ by, data, inactive, client, site }, getPayables) {
    // Remove when BE supports all methods
    by = this.searchByMap[by]
    if (!by) {
      throw new Error('Backend does not support this at the moment')
    }
    try {
      const response = await this.searchSchedules({
        by,
        data,
        inactive,
        client,
        site,
      })
      const schedules = await this.parseSchedules(response, getPayables)

      // Filter out any schedules with undefined payables (see docstring)
      return schedules.filter(schedule => Boolean(schedule.payable))
    }
    catch (error) {
      console.error(error)
      return []
    }
  }

  async searchSchedules ({
    by = undefined,
    data = undefined,
    inactive,
    client,
    site,
  }) {
    return await this.post('/v2/plans/action/search', {
      [by]: data,
      includeInactive: inactive,
      sites: [{ client, site }],
    })
  }

  async checkExistence (payablePath) {
    return await this.post('/v2/plans/action/checkExistence', payablePath)
  }

  // gets a single schedule by the schedule id
  async getScheduleDetails (scheduleId) {
    try {
      const response = await this.get(`/v2/plans/${scheduleId}?includeSummary=true`)
      return response.data
    }
    catch (error) {
      console.error(error)
      return {}
    }
  }

  // helper function to turn a list of backend schedules into our frontend schedule model
  // NOTE back end only supports 4 frequency types right now and not yet 'x days before due':
  // OneTimeOnDate
  // RecurringMonthlyOnDate
  // RecurringYearlyOnDate
  // RecurringEveryXWeeks
  async parseSchedules ({ data }, getPayables) {
    if (!data.length) {
      return []
    }

    const allPayables = await getPayables(
      data.map(
        sched => sched.payablePath,
      ),
    )

    const scheduleList = []
    for (let i = 0; i < data.length; i++) {
      const rawSchedule = data[i]
      const payable = allPayables[i]

      const {
        frequencyRule,
        constantAmount,
        tender,
        user,
        id,
        suspensionStart,
        suspensionEnd,
        active,
        adminOnly,
        agreement,
        client,
        site,
        requiresTenderChange,

        createdAt,
        modifiedAt,
        modifiedBy,
      } = rawSchedule

      scheduleList.push(
        // The backend gives us a date in format yyyy-mm-dd, which we have to
        // modify to make work
        new Schedule({
          payable,

          frequency: buildFrequency(frequencyRule),
          amount: {
            // XXX: This might eventually have to be handled differently, once
            // there are other amount specs
            spec: constantAmount ? 'otherAmount' : 'amountDue',
            value: constantAmount || '',
          },
          tender,
          user: {
            email: user.email,
            sub: user.id,
            phone: user.phone,
            name: user.name,
            language: user.language || 'en',
          },
          id,
          pause: {
            startDate: suspensionStart ? getTimelessDateFromString(suspensionStart) : null,
            endDate: suspensionEnd ? getTimelessDateFromString(suspensionEnd) : null,
          },
          active,
          adminOnly,
          agreement,
          client,
          site,
          requiresTenderChange,

          createdAt,
          modifiedAt,
          modifiedBy,
        }),
      )
    }

    return scheduleList
  }
}
