/**
 * Metadata about a file uploaded to the PSP uploads service
 */
export default class Upload {
  /**
   * UUID for the uploads-service file, e.g:
   * 'uploads-service:0f018aa3-32e0-45fe-9f2a-2a6c92b71615'
   */
  id: string

  /**
   * The file's name
   */
  name: string

  /**
   * A UUID assigned for identifying the file for retrievals. This is not the
   * same as the `id` above, which is the GUC ID assigned on upload.
   */
  fileId?: string

  /**
   * File's size, in bytes (if available)
   */
  sizeBytes?: number

  /**
   * A string containing the file's MIME type (if available)
   */
  mimeType?: string

  constructor (data: Pick<Upload,
    'id' |
    'name' |
    'fileId' |
    'sizeBytes' |
    'mimeType'
  >) {
    this.id = data.id
    this.name = data.name
    this.fileId = data.fileId
    this.sizeBytes = data.sizeBytes
    this.mimeType = data.mimeType
  }
}
