<template>

  <!-- The row+column structure here mirrors the structure in CartTotals.vue so
  that the labels and totals are aligned at all screen widths. -->

  <section
    :aria-label="title || $t('checkout.cart.default')"
    class="bg-white rounded-xl p-4 mb-2 mb-md-3 position-relative"
  >
    <div class="d-md-flex align-items-center mb-3">
      <h2 class="mb-1 mb-md-0">{{ title || $t('checkout.cart.default') }}</h2>
    </div>

    <div
      v-if="itemRemovedMessage"
      ref="itemRemovedStatementAria"
      class="sr-only"
      aria-live="assertive"
      tabindex="0"
    >
      {{ itemRemovedMessage }}
    </div>

    <div class="pb-3">
      <div
        :class="{
          'hint-more-items': collapseCartItems,
        }"
      >
        <div
          v-for="itemDisplayType in itemsByDisplayType"
          :key="itemDisplayType.configDisplayType.name"
          class="px-3 pb-3 pt-2"
        >
          <h6 class="section-heading row align-items-center">
            <svgicon
              v-if="itemDisplayType.configDisplayType.icon"
              :fill="false"
              :icon="itemDisplayType.configDisplayType.icon"
              height="1.25rem"
              width="1.25rem"
              class="mr-2"
              aria-hidden="true"
            />
            {{ itemDisplayType.configDisplayType.title[$i18n.locale] || '' }}
          </h6>

          <!-- Payment limit warning -->
          <!-- If there are any over-limit types, show a warning.
              If there are no allowed types under limit, make it red. -->
          <Alert
            v-if="siteEnabledTenderTypesOverLimitByDepartment[itemDisplayType.items[0].payable.departmentCode].length"
            :variant="allowedTenderTypesUnderLimitByDepartment[itemDisplayType.items[0].payable.departmentCode].length ? 'warning' : 'danger'"
            data-test="payment-limit-warning"
            :dismissible="false"
            :show-icon="false"
            class="payment-limit-error row mb-0"
          >
            <!-- ^ The .payment-limit-error class is used to scroll to these errors -->
            {{ getPaymentLimitMessage(itemDisplayType.items[0].payable.departmentCode) }}
          </Alert>

          <!-- Nested Payable item -->
          <div v-if="itemDisplayType.childSiblings">
            <div
              v-for="{ savePath, displayName, description, children } of itemDisplayType.childSiblings"
              :key="savePath"
              data-test="nested-items"
              class="item py-3"
            >
              <h4 class="text-body mb-0">
                {{ displayName }}
              </h4>
              <div
                v-if="description"
                class="preserve-newlines"
              >{{ description }}
              </div>

              <CartItem
                v-for="item in children"
                :key="item.payable.path"
                data-test="cart-item"
                :item="item"
                :allows-autopay="false"
                :is-selection-view="view === 'selection'"
                :show-delivery-option-description="showDeliveryOptionDescription"
                is-child
                @update-error="updateError"
              />
            </div>
          </div>

          <!-- Cart item -->
          <CartItem
            v-for="item of itemDisplayType.singleItems"
            :key="item.payable.path"
            ref="cartItem"
            tabindex="0"
            data-test="cart-item"
            :item="item"
            :allows-autopay="allowsAutopay"
            :is-selection-view="view === 'selection'"
            :show-delivery-option-description="showDeliveryOptionDescription"
            :selected-tender-supports-autopay="selectedTenderSupportsAutopay"
            @update-error="updateError"
            @item_removed="handleItemRemovalAria(item, itemDisplayType.singleItems)"
          />
        </div>
      </div>

      <div
        v-if="cart.items.length > maxVisibleCartSize"
        :class="{
          'less-items': !showAllItems,
        }"
        class="cart-collapse-pane border-bottom border-top mb-3 px-3 py-2 d-flex position-relative"
      >
        <div
          v-dompurify-html="$t('cart.collapsible.total', {
            number: displayFormat(cart.items.length, true),
          })"
          class="mr-2"
        />
        <div>
          <b-link
            data-test="show-all-items-link"
            @click="toggleAllItems"
          >
            {{
              showAllItems
                ? $t('cart.collapsible.show_less')
                : $t('cart.collapsible.show_all')
            }}
          </b-link>
        </div>
      </div>

      <b-row class="px-3">
        <b-col
          cols="12"
          md="6"
          lg="7"
          class="order-2 order-md-1 pt-3 pt-md-0"
        />

        <b-col
          cols="12"
          md="6"
          lg="5"
          class="order-1 order-md-2"
        >

          <!-- Subtotal -->
          <!-- Total isn't displayed in card so it's okay to show a subtotal for
          a single transaction -->
          <b-row
            v-if="!hideSubtotals"
            tabindex="0"
          >
            <b-col
              cols="6"
              lg="7"
              class="d-flex align-items-center justify-content-md-end text-md-right"
              :class="{'bigger-subtotal': useCostLinkedPricing}"
            >
              {{ $t('subtotal.label') }}
            </b-col>
            <b-col
              cols="6"
              lg="5"
              class="d-flex align-items-center justify-content-end cart-amount-small"
              :class="{'cart-amount-medium': useCostLinkedPricing}"
              data-test="cart-subtotal"
            >
              $ {{ displayFormat(cart.subtotal) }}
            </b-col>
          </b-row>

          <template v-if="displayFeesAsTBD && !useCostLinkedPricing">
            <b-row>
              <b-col
                cols="6"
                lg="7"
                class="d-flex align-items-center justify-content-md-end text-md-right"
              >
                <span id="tbd-fee-hoverzone">
                  {{ $t('fee.label') }}
                  <svgicon
                    :fill="false"
                    icon="question-mark-circle"
                    width="1.125rem"
                    height="1.125rem"
                    class="ml-2"
                  />
                </span>
                <b-tooltip
                  target="tbd-fee-hoverzone"
                  placement="top"
                  triggers="hover"
                  :title="$t('fee.tbd.explanation')"
                  @shown="handleToolTipShown"
                />
              </b-col>
              <b-col
                cols="6"
                lg="5"
                class="d-flex align-items-center justify-content-end cart-amount-small"
                data-test="cart-fees"
              >
                {{ $t('tbd.label') }}
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="6"
                lg="7"
                class="d-flex align-items-center justify-content-md-end text-md-right"
              >
                {{ $t('total.label') }}
              </b-col>
              <b-col
                cols="6"
                lg="5"
                class="d-flex align-items-center justify-content-end cart-amount-small"
                data-test="cart-total"
              >
                {{ $t('tbd.label') }}
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>

    </div>
    <b-row
      v-if="useCostLinkedPricing"
      class="px-3"
    >
      <b-col
        cols="12"
        lg="5"
      />
      <b-col
        cols="12"
        lg="7"
        class="d-flex align-items-center justify-content-end text-sm-right text-xs-left"
      >
        <span>{{ $t('fee.alternate.message') }}
          <ConvenienceFeeModal
            :translated-fee-keys="translatedFeeKeys()"
            class="ml-1 text-left"
          />
        </span>
      </b-col>
    </b-row>
    <div
      v-if="userCanContinueShopping"
      class="cart-footer col-12 col-md-6 col-lg-5"
    >
      <a
        href="#"
        @click.prevent="$wait.waiting(`modifying.*`) ? '' : $store.dispatch('Cart/emptyCart')"
      >{{ $t("cart.empty.default") }}</a>
      <a
        href="#"
        @click.prevent="$router.push({ name: continueShoppingRoute, params: $route.params })"
      >{{ $t("return.home") }}</a>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapConfigGetters } from '@grantstreet/psc-config'
import { displayFormat, parseNumber } from '@grantstreet/psc-js/utils/numbers.js'
import { translateAndList } from '@grantstreet/psc-vue/utils/i18n.ts'
import { getItemsByDisplayType } from '../store/helpers.js'
import ConvenienceFeeModal from '@grantstreet/e-wallet-vue/src/components/ConvenienceFeeModal.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import CartItem from './CartItem.vue'
import Vue from 'vue'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'

export default {
  components: {
    Alert,
    CartItem,
    ConvenienceFeeModal,
  },

  mixins: [scrollToMixin],

  props: {
    hideSubtotals: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '', // defaults to "Shopping Cart" in the template
    },
    allowsAutopay: {
      type: Boolean,
      default: false,
    },
    // This is the tender currently selected in E-Wallet. This can be different
    // from `this.chosenTender`, which is only set after the user _submits_
    // E-Wallet and goes to the confirmation page.
    tenderType: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: '',
    },
    showDeliveryOptionDescription: {
      type: Boolean,
      default: true,
    },
    selectedTenderSupportsAutopay: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      updateErrors: {},

      // This is a totally arbitrary number that can be adjusted at any point.
      // This makes it so the cart only displays up to the `maxVisibleCartSize`
      // until a user clicks "Show all items".
      maxVisibleCartSize: 10,
      showAllItems: false,
      itemRemovedMessage: '',
    }
  },

  computed: {
    continueShoppingRoute () {
      const configRoute = (
        this.searchPages.length &&
        this.searchPages.length === 1 &&
        typeof this.searchPages[0].route !== 'undefined'
      ) ? this.searchPages[0].route
        : false

      return configRoute || 'home'
    },

    itemsByDisplayType () {
      return getItemsByDisplayType(this.cart.items)
    },

    // A map of: lists of tender types (*enabled at the site level*), for which
    // the subtotal of items in a PEx department is over that type's payment
    // limit.
    // E.g.
    // {
    //   tax: ['card', 'paypal'],
    //   airport: ['bank'],
    //   utility: [],
    // }
    // I know the name is brutal but complexity is growing and we're having
    // trouble keeping straight thing like site enabled, payable enabled,
    // allowed, and restricted tender types; as well as things like tender
    // types, sources, "methods/forms". The distinctions are important and this
    // is exactly what it says on the tin.
    siteEnabledTenderTypesOverLimitByDepartment () {
      const byDepartment = {}

      for (const displayType of this.itemsByDisplayType) {
        const pexDepartment = displayType.items[0].payable.departmentCode

        if (!pexDepartment) {
          byDepartment[pexDepartment] = []
          continue
        }

        // Default limit will be returned on redirect only sites until PSC-3857 is
        // done
        const paymentLimits = this.getPaymentLimits(pexDepartment)
        if (!paymentLimits) {
          byDepartment[pexDepartment] = []
          continue
        }
        const { cardLimit, bankLimit } = paymentLimits
        const subtotal = this.getDepartmentSubtotal(pexDepartment)
        const over = []

        if (
          bankLimit &&
          this.banksSiteEnabled &&
          subtotal > parseNumber(bankLimit)
        ) {
          over.push('bank')
        }

        if (
          cardLimit &&
        subtotal > parseNumber(cardLimit)
        ) {
          if (this.cardsSiteEnabled) {
            over.push('card')
          }
          // PayPal is its own tender type but subject to the card limit; it doesn't
          // get its own.
          if (this.payPalSiteEnabled) {
            over.push('paypal')
          }
        }

        byDepartment[pexDepartment] = over
      }

      return byDepartment
    },

    // A map of *allowed* tender types that are under payment limits, by pex
    // department.
    // The inverse of siteEnabledTenderTypesOverLimitByDepartment filtered for
    // types that are allowed by all payables.
    allowedTenderTypesUnderLimitByDepartment () {
      const byDepartment = {}
      for (const [department, overLimitTypes] of Object.entries(this.siteEnabledTenderTypesOverLimitByDepartment)) {
        byDepartment[department] = this.allowedPayableMethods.filter(type => !overLimitTypes.includes(type))
      }

      return byDepartment
    },

    collapseCartItems () {
      return this.cart.items.length > this.maxVisibleCartSize && !this.showAllItems
    },

    // If a site has only redirect payable sources, there's no way to
    // add more items from the PayHub site, so we don't allow users to "Empty
    // cart" or "Continue shopping."
    userCanContinueShopping () {
      return !this.siteUsesRedirectOnly
    },

    feeName () {
      return this.$t('fee.name')
    },

    ...mapGetters('PayHub', [
      'translatedFeeKeys',
      'searchPages',
      'getPaymentLimits',
      'clientUrl',
    ]),

    ...mapGetters('Cart', [
      'cardsSiteEnabled',
      'banksSiteEnabled',
      'displayFeesAsTBD',
      'payPalSiteEnabled',
      'allowedAlternativeTenderSources',
      'allowedPayableMethods',
      'siteEnabledPaymentMethods',
    ]),

    ...mapState('Cart', [
      'cart',
    ]),

    ...mapConfigGetters([
      'siteUsesRedirectOnly',
      'useCostLinkedPricing',
    ]),
  },

  methods: {
    displayFormat,
    parseNumber,

    getItemsByDisplayType,

    updateError ({ id, event }) {
      Vue.set(this.updateErrors, id, event)
    },

    // Returns the subtotal (not including fees) for all items in the passed PEx
    // department.
    getDepartmentSubtotal (pexDepartment) {
      return this.itemsByDepartment(pexDepartment).reduce((total, item) => {
        // If the API returns no value for the quantity field, assume it is a
        // single-quantity item. This _should_ never happen.
        const quantity = typeof item.quantity === 'undefined'
          ? 1
          : parseNumber(item.quantity)
        const itemTotal = (parseNumber(item.amount) * quantity) || 0
        return total + itemTotal
      }, 0)
    },

    handleToolTipShown () {
      this.$gtag.event(
        // Log that the user viewed the tooltip to Google Analytics
        'Fee Tooltip Opened',
        {
          'event_category': 'E-Wallet',
        },
      )
    },

    // Notify Aria screen reader users of a removal and set focus to allow
    // them to continue in their original workflow
    handleItemRemovalAria (itemRemoved, allItems) {
      this.itemRemovedMessage = this.$t('item.removed')
      const cartItems = this.$refs.cartItem
      let indexToFocus

      if (cartItems && cartItems.length === 0) {
        return
      }

      for (let i = 0; i < allItems.length; i++) {
        const currItem = allItems[i]

        if (itemRemoved.id === currItem.id && itemRemoved.path === currItem.path) {
          indexToFocus = i === 0 ? 0 : i - 1
          break
        }
      }

      this.$nextTick(() => {
        setTimeout(() => {
          const removedStatement = this.$refs.itemRemovedStatementAria

          if (removedStatement) {
            removedStatement.focus()
          }
        }, 100)
      })

      this.$nextTick(() => {
        setTimeout(() => {
          this.itemRemovedMessage = ''
          const itemToFocus = this.$refs.cartItem[indexToFocus]

          if (itemToFocus) {
            const itemDetails = itemToFocus.$refs.cartItemDetails

            if (itemDetails) {
              itemDetails.focus()
            }
          }
        }, 3000)
      })
    },

    // Returns the translated payment limit warning for the passed PEx
    // department. This warning includes a reference to the applicable display
    // types.
    // If the department subtotal exceeds a single payment limit then the
    // message will list *all tender types enabled on the site* which have a
    // limit. (Even if a tender type is disabled for a specific payable). This
    // is so that the user knows the all limitations and doesn't attempt to edit
    // their total or cart contents in an incompatible way.
    // E.g.
    // Bad situation:
    // We show bank limits, the user removes an item preventing card payments,
    // and then we show the user card limits.
    getPaymentLimitMessage (pexDepartment) {
      const { cardLimit, bankLimit, paymentLimitDelay } = this.getPaymentLimits(pexDepartment)
      const paymentDelay = paymentLimitDelay?.[this.$i18n.locale]

      const itemTypes = translateAndList([
        ...new Set(this.itemsByDepartment(pexDepartment).map(item => item.payable.configDisplayType.title[this.$i18n.locale])),
      ])

      // ***********************************************************************
      // Generate limit message
      // ***********************************************************************

      let message

      // We currently explicitly only support card and bank limits
      const bankNumber = parseNumber(bankLimit || 0)
      const cardNumber = parseNumber(cardLimit || 0)

      // card and paypal are true tender types so they need to be checked.
      // Alternative card sources are completely dependent on card and therefore
      // don't.
      const limitCardLike = cardNumber && (this.cardsSiteEnabled || this.payPalSiteEnabled)
      const limitBank = bankNumber && this.banksSiteEnabled

      let bankFriendlyName
      let cardLikeFriendlyNames

      if (limitBank) {
        bankFriendlyName = this.$t('bank.friendly_name.plural')
      }

      if (limitCardLike) {
        cardLikeFriendlyNames = []
        if (this.cardsSiteEnabled) {
          cardLikeFriendlyNames.push('card')
        }
        if (this.payPalSiteEnabled) {
          cardLikeFriendlyNames.push('paypal')
        }
        // Tender Sources !== Payment Methods. But we list them together here.
        // See Cart store.
        if (this.allowedAlternativeTenderSources?.length) {
          cardLikeFriendlyNames = [...cardLikeFriendlyNames, ...this.allowedAlternativeTenderSources]
        }
        // This seems odd for some items, but anything in this list would
        // normally have plural declension (e.g. cards, coins, accounts,
        // chocolate kisses). In this case the plural of PayPal is PayPal.
        cardLikeFriendlyNames = cardLikeFriendlyNames.map(source => this.$t(`${source}.friendly_name.plural`))
      }

      if (
        limitCardLike && limitBank
      ) {
        // Joint message:
        // "You may only pay up to ${sharedLimit} for {itemTypes} items using
        // {methods}."
        // Remember to confirm translations with the file
        if (bankNumber === cardNumber) {
          message = this.$t('payable_sources.payment_limit.joint', {
            sharedLimit: cardLimit,
            itemTypes,
            methods: translateAndList([bankFriendlyName, ...cardLikeFriendlyNames]),
          })
        }
        // Mixed message:
        // "You may only pay up to ${lesserLimit} for {itemTypes} items using
        // {lesserLimitMethods}{separator} and ${greaterLimit} using
        // {greaterLimitMethods}."
        else {
          let lesserLimit, lesserLimitMethods
          let greaterLimit, greaterLimitMethods
          let separator = ','

          if (bankNumber > cardNumber) {
            greaterLimit = bankLimit
            greaterLimitMethods = bankFriendlyName

            lesserLimit = cardLimit
            // translateAndList gracefully handles single item lists
            lesserLimitMethods = translateAndList(cardLikeFriendlyNames)
            if (cardLikeFriendlyNames.length > 1) {
              // Semicolon is only necessary to clearly delineate the difference
              // between a conjoined list of lesserMethods (which come in the
              // middle of the sentence) and the conjunction which immediately
              // follows and joins the clauses.
              separator = ';'
            }
          }
          else {
            greaterLimit = cardLimit
            greaterLimitMethods = translateAndList(cardLikeFriendlyNames)

            lesserLimit = bankLimit
            lesserLimitMethods = bankFriendlyName
          }
          message = this.$t('payable_sources.payment_limit.mixed', {
            lesserLimit,
            itemTypes,
            lesserLimitMethods,
            separator,
            greaterLimit,
            greaterLimitMethods,
          })
        }
      }
      // Single message:
      // "You may only pay up to ${limit} for {itemTypes} using {methods}."
      else {
        message = this.$t('payable_sources.payment_limit.single', {
          limit: displayFormat(bankLimit || cardLimit),
          itemTypes,
          methods: bankNumber ? bankFriendlyName : translateAndList(cardLikeFriendlyNames),
        })
      }

      // ***********************************************************************
      // Generate user instructions
      // ***********************************************************************

      let delayMessage = paymentDelay ? 'delay' : 'general'
      delayMessage = this.$t(`payable_sources.payment_limit.instructions.${delayMessage}`, { paymentDelay })

      message += ` ${delayMessage}`
      return message
    },

    itemsByDepartment (pexDepartment) {
      return this.cart.items.filter(item => item.payable.departmentCode === pexDepartment)
    },

    validate (tender) {
      let result = true
      // Check if any of our cart items have errors
      if (Object.values(this.updateErrors).some(item => Boolean(item))) {
        result = false
      }

      const cartItemRefs = this.$refs.cartItem
      if (cartItemRefs && cartItemRefs.length) {
        for (const input of cartItemRefs) {
          if (!input.validate()) {
            result = false
          }
        }
      }

      // If tender is passed then we can validate against payment limits
      if (tender?.type) {
        // Validate payment limits (we will scroll to the limit warning on submit
        // if a PEx department limit is exceeded)
        for (const item of this.cart.items) {
          if (!this.allowedTenderTypesUnderLimitByDepartment[item.payable.departmentCode].includes(tender.type)) {
            result = false
            break
          }
        }
      }

      // If any of the cart items have updated payables, disallow checkout until
      // they either update or remove the cart item.
      if (this.cart.items.reduce((hasUpdatedPayable, { updatedPayable }) => hasUpdatedPayable || updatedPayable, false)) {
        result = false
      }

      // If any of the cart items were not found, disallow checkout until they
      // they remove item.
      if (this.cart.items.reduce((hasNotFoundPayable, item) => hasNotFoundPayable || item.payableNotFound, false)) {
        result = false
      }

      return result
    },

    // This can be called by parent components.
    scrollToFirstError () {
      this.scrollTo(`
        .item .details .is-invalid ~ .invalid-tooltip,
        .item .cart-amount .is-invalid ~ .invalid-tooltip,
        .payment-limit-error,
        .updated-payable-alert
      `, { offset: -170 })
    },

    toggleAllItems () {
      this.showAllItems = !this.showAllItems
    },
  },
}
</script>

<style lang="scss" scoped>
.hint-more-items {
  height: 700px;
  overflow-y: hidden;
}

.section-heading {
  background: $light-2;
  font-weight: normal;
  padding: 0.625rem 1rem;
}

.headline {
  font-weight: bold;
  font-size: 1.125rem;
}

.bigger-subtotal {
  font-size: 1.5rem;
}
.cart-collapse-pane {
  &.less-items::before {
    position: absolute;
    display: block;
    content: '';
    height: 250px;
    width: 100%;
    bottom: 100%;
    left: 0;
    background: linear-gradient(180deg, rgba(128, 128, 128, 0) 0%, rgba(255, 255, 255, 1) 100%);
    z-index: 100;
    pointer-events: none;
  }

  font-size: 0.875rem;
  background-color: $light-2;
}

.cart-footer {
  bottom: 0;
  left: 0;
  margin-left: map-get($spacers, 3);

  @include media-breakpoint-up(md) {
    // Go ahead and let content overflow the bottom since it's absolutely
    // positioned and will overflow if there's too much content anyway. This
    // makes multi-line content line up properly on both cart and receipt pages.
    height: 45px;
    position: absolute;
    margin-bottom: map-get($spacers, 5);
    margin-left: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 3);
    padding-left: map-get($spacers, 3);
  }

  a {
    border-left: $border-width solid $border-color;
    display: inline-block;
    padding: .25rem 1rem;

    &:first-child {
      border-left: 0;
      padding-left: 0;
    }

    @include media-breakpoint-375-down {
      border-left: 0;
      padding-left: 0;
      padding-right: 1.5rem;
    }
  }
}

</style>
