// TODO PSC-17126 make this useable by non-monorepo code
import Vue from 'vue'
import Vuex from 'vuex'
// *Core Deps*
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
// *API Clients*
import NonProdConfigAPI from '../api/non-prod.js'
import ProdConfigAPI from '../api/prod.js'
// *Utils*
import { sentryException } from '../sentry.ts'
import { isProd } from '@grantstreet/psc-environment'
import { TranslatedTextObject, TranslatedTextObjects, addI18n, filterSpecialCharacters } from '@grantstreet/psc-vue/utils/i18n.ts'
import { sortByProp } from '@grantstreet/psc-js/utils/sort.js'
import { firstToUpperCase } from '@grantstreet/psc-js/utils/cases.js'
import LDClient, { LDFlagChangeset } from 'launchdarkly-js-client-sdk'
import VueCookies from 'vue-cookies'
import Session from '@grantstreet/psc-vue/utils/session.js'
import UAParser from 'ua-parser-js'
import isEmpty from 'lodash/isEmpty.js'
import cloneDeep from 'lodash/cloneDeep.js'
// *Core Deps*
import {
  formatSiteConfigFromService,
  overrideFlaggedConfigs,
  objectifyConfigs,
  parseSiteSettings,
  generateClientList,
  moduleEnabled,
  getSiteSetting,
} from '../utils.ts'
import ewalletModule from '../modules/ewallet.json'
import cartModule from '../modules/cart.json'
import cartRedirectModule from '../modules/cart-redirect.json'
import checkoutWidgetModule from '../modules/checkout-widget.json'
import deliveryModule from '../modules/delivery.json'
import helpModule from '../modules/help.json'
import loginModule from '../modules/login.json'
import messagingModule from '../modules/messaging.json'
import myPaymentsModule from '../modules/mypayments.json'
import mySavedThingsModule from '../modules/mysaveditems.json'
import payablesModule from '../modules/payables.json'
import payablesStorageModule from '../modules/payables-storage.json'
import payHubModule from '../modules/payhub.json'
import paymentexpressModule from '../modules/paymentexpress.json'
import schedpayModule from '../modules/schedpay.json'
import searchModule from '../modules/search.json'
import payableSourcesModule from '../modules/payable-sources.json'
import taxsysModule from '../modules/taxsys.json'
import announcementsModule from '../modules/announcements.json'
import formsModule from '../modules/forms.json'
import welcomeModule from '../modules/welcome.json'
import mobileModule from '../modules/mobileapp.json'
import renewexpressModule from '../modules/renewexpress.json'
import chatBotModule from '../modules/chatbot.json'
import uploadsModule from '../modules/uploads.json'
import renderingModule from '../modules/rendering.json'
import { isAxiosError } from 'axios'
import type { Setting } from '../types/settings.js'
import directChargeWidgetModule from '../modules/direct-charge-widget.json'
import embeddedPublicSiteModule from '../modules/embedded-public-site.json'
import testingOnlyModule from '../modules/testing-only.json'

Vue.use(Vuex)

export type InstallParams = {
  nonProdJwt?: string
  prodJwt?: string
  cacheGETs?: boolean
  sanityCheckClientLevelSettings?: boolean
  logDiagnostics?: (data) => void
}

class State {
  loadConfigPromise: Promise<void> | undefined
  loadConfigGlobalsPromise: Promise<void> | undefined
  moduleList = {
    [ewalletModule.id]: ewalletModule,
    [helpModule.id]: helpModule,
    [loginModule.id]: loginModule,
    [messagingModule.id]: messagingModule,
    [myPaymentsModule.id]: myPaymentsModule,
    [mySavedThingsModule.id]: mySavedThingsModule,
    [payablesModule.id]: payablesModule,
    [payablesStorageModule.id]: payablesStorageModule,
    [payHubModule.id]: payHubModule,
    [paymentexpressModule.id]: paymentexpressModule,
    [schedpayModule.id]: schedpayModule,
    [cartModule.id]: cartModule,
    [cartRedirectModule.id]: cartRedirectModule,
    [checkoutWidgetModule.id]: checkoutWidgetModule,
    [searchModule.id]: searchModule,
    [payableSourcesModule.id]: payableSourcesModule,
    [taxsysModule.id]: taxsysModule,
    [announcementsModule.id]: announcementsModule,
    [chatBotModule.id]: chatBotModule,
    [welcomeModule.id]: welcomeModule,
    [deliveryModule.id]: deliveryModule,
    [mobileModule.id]: mobileModule,
    [formsModule.id]: formsModule,
    [renewexpressModule.id]: renewexpressModule,
    [uploadsModule.id]: uploadsModule,
    [directChargeWidgetModule.id]: directChargeWidgetModule,
    [embeddedPublicSiteModule.id]: embeddedPublicSiteModule,
    [renderingModule.id]: renderingModule,
    [testingOnlyModule.id]: testingOnlyModule,
  }

  // Global config service flags
  globals: Setting = {}

  /**
   * config is the "current" site's configuration. This is where most
   * things should get their configuration. Only one site can be
   * "current" at a time, and only admin modules currently have a way to
   * change which site is "current" inside of a single page.
   */
  config: Setting = {}

  /**
   * allConfigs stores any configuration we've requested that is not for
   * the "current" site. This is generally used by admin modules which
   * need to allow a user to pick a client/site to work with.
   *
   * This may not be complete, so an admin module should make sure to
   * load the configuration it needs during initialization. See
   * `loadAdminConfig()` and `loadAdminModule()` to do this.
   */
  allConfigs = {}

  // LaunchDarkly flags
  ldClient = undefined
  flags = {}
  // Used by LaunchDarkly, set by parent application
  ldMetadata = {}

  clientList = []
  // JWTs and API clients
  nonProdJwt = undefined
  prodJwt = undefined
  // Used during loadConfig
  clientMeta = {}
  // Used for defaultSiteUrl to check if a site exists
  siteMeta = {}

  cacheGETs = true
  sanityCheckClientLevelSettings = undefined

  // The Okta scopes for user
  adminScope = []
  // For embedded UIs like Schep Admin & Site Settings, this value comes
  // from a remoteUserJwt value passed to the embedded component
  adminUserJwt = ''

  // A list of all sites in the format:
  // [
  //   {
  //     client: 'volusia',
  //     site: 'water',
  //   },
  //   {
  //     client: 'volusia',
  //     site: 'utilities',
  //   },
  // ]
  clientSiteMasterList = []

  // A list of all sites the user has permissions for
  clientSiteAccessList = []

  // A list of all sites keyed to clients
  masterSitesPerClient = {}

  // Critical for logging
  logDiagnostics = () => {
    throw new Error('psc-config logging dependency not set.')
  }
}

const getterDefinitions = {
  client: state => state.config.client,
  site: state => state.config.site,

  sessionId: () => {
    return Session.id
  },

  nonProdApi: ({ nonProdJwt, cacheGETs }) => new NonProdConfigAPI({
    jwt: nonProdJwt,
    cacheGETs,
    exceptionLogger: sentryException,
  }),

  prodApi: ({ prodJwt, cacheGETs }) => new ProdConfigAPI({
    jwt: prodJwt,
    cacheGETs,
    exceptionLogger: sentryException,
  }),

  // Because the API clients make their own calculation including jwt, this
  // should always reflect the actual state of the client
  isSandbox: (_state, getters) => getters.prodApi.isSandbox,

  // Necessary for drop-downs
  // Remember loadAllConfigs needs to be called before this can be used
  friendlyClientName: state => (clientKey, siteKey) =>
    (
      state.allConfigs?.[clientKey]?.[siteKey] ||
      Object.values(state.allConfigs?.[clientKey] || {})?.[0]
    )?.meta?.clientName || clientKey,

  friendlySiteName: state => (clientKey, siteKey) =>
    state?.allConfigs?.[clientKey]?.[siteKey]?.meta?.siteName || siteKey,

  // Module enablement
  useAnnouncements: state => moduleEnabled(state.config.announcements),
  useAnnouncementsBanners: (state, getters) =>
    getters.useAnnouncements &&
    state.config.announcements.enableBanners,
  useAnnouncementsNotifications: (state, getters) =>
    getters.useAnnouncements &&
    state.config.announcements.enableNotifications,
  useAnnouncementsFooters: (state, getters) =>
    getters.useAnnouncements &&
    state.config.announcements.enableFooters,
  preExpandAnnouncementsFooter: (state, getters) =>
    getters.useAnnouncementsFooters &&
    state.config.announcements.preExpandFooters,
  siteUsesAnnouncements: (_state, getters) =>
    getters.useAnnouncementsBanners ||
    getters.useAnnouncementsNotifications ||
    getters.useAnnouncementsFooters,
  siteUsesAnnouncementsFeature: () => ({ config, feature }) =>
    moduleEnabled(config.announcements) &&
    config.announcements[`enable${firstToUpperCase(feature)}`],
  siteUsesHomepage: (_state, getters) =>
    // .some returns false for empty arrays
    getters.payableSources.some(({ sourceType }) => sourceType !== 'redirect'),
  siteUsesRedirectOnly: (_state, getters) =>
    getters.payableSources.length &&
    // .every returns true for empty arrays
    getters.payableSources.every(({ sourceType }) => sourceType === 'redirect'),
  siteUsesFormsOnly: (_state, getters) =>
    getters.payableSources.length === 0 &&
    getters.useForms,
  useCart: state => moduleEnabled(state.config.cart),
  useChatBot: (state, getters) => moduleEnabled(state.config.chatBot) &&
    getters.chatBotUrl,
  useClientOnlyUrl: state => state.config.useClientOnlyUrl,
  useDelivery: state => moduleEnabled(state.config.delivery),
  useForms: state => moduleEnabled(state.config.forms),
  useHelp: state => moduleEnabled(state.config.help),
  useLogin: state => moduleEnabled(state.config.login),
  useMyPayments: state => moduleEnabled(state.config.myPayments),
  useMySavedItems: state => moduleEnabled(state.config.mySavedItems),
  useSchedPay: state => moduleEnabled(state.config.schedPay),
  useMyDashboard: (_state, getters) =>
    getters.useForms ||
    getters.useMyPayments ||
    getters.useMySavedItems ||
    getters.useTaxsysMyAccounts ||
    getters.useLogin ||
    getters.useEbilling,
  // E-Billing & User Verification settings are payable-source-specific so the following
  // non-payable-source-specific version of their settings should only be used for use cases
  // where we need to know if any payable source has the setting turned on
  // e.g. when determining whether to show the subscription management tab in My Items
  useEbilling: (_state, getters) =>
    getters.payableSources.length &&
    getters.payableSources.some(payableSource => payableSource.useEbilling),
  useUserVerification: (_state, getters) =>
    getters.payableSources.length &&
    getters.payableSources.some(payableSource => payableSource.useUserVerification),

  useEmbeddedPublicSite: state =>
    (moduleEnabled(state.config.embeddedPublicSite) && window.self !== window.top),
  useRenewExpress: state => moduleEnabled(state.config.renewexpress),
  // A new, medium-term workaround to enable a whole slew of features.
  useTaxsysMyAccounts: state => state.config.taxsys?.useMyAccounts,
  useTaxsysToGovhubFeatures: ({ flags, config }) => flags[`use-taxsys-to-govhub-features.${config.client}-${config.site}`],
  useBtExpress: (state, getters) => (getters.useTaxsysToGovhubFeatures && state.config.taxsys?.useBtExpress),
  useReports: (state, getters) => getters.useTaxsysToGovhubFeatures && state.config.taxsys?.reports?.useReports,
  reportsLinkMeta: (state, getters) => getters.useReports ? { navOrder: state.config.taxsys?.reports?.navOrder } : null,

  // Note: you still need to check
  // window.ApplePaySession?.canMakePayments?.()
  // and make sure the cart doesn't have delayed items
  // cart.hasDelayedItems
  useApplePay: state => {
    // If a launch darkly flag exists, respect that flag
    const flag = `use-apple-pay.${state.config.client}-${state.config.site}`
    if (state.flags?.hasOwnProperty(flag)) {
      return state.flags[flag]
    }

    // Otherwise default to the site setting
    return state.config.eWallet.enableApplePay
  },

  useGooglePay: state => state.config.eWallet?.enableGooglePay,

  useExternalCheckout: state => state.config.cart.enableExternalCheckout,

  useFeeCheckbox: state => state.config.eWallet?.showFeeCheckbox,

  // Setting default is [] but a fallback is still needed for optional chaining
  payableSources: state => state.config?.payableSources?.payableSources || [],

  displayTypeUsesEbilling: (_state, getters) => displayType =>
    getters.payableSources.length &&
    getters.payableSources.some(source =>
      source.useEbilling &&
      source.displayType === displayType),

  displayTypeAllowPaperlessEbilling: (_state, getters) => displayType =>
    getters.payableSources.length &&
    getters.payableSources.some(source =>
      source.useEbilling &&
      source.allowPaperlessEbilling &&
      source.displayType === displayType),

  // Note pinGateEbilling only deals with pin gating paperless ebilling
  displayTypePinGateEbilling: (_state, getters) => displayType =>
    getters.payableSources.length &&
    getters.payableSources.some(source =>
      source.useUserVerification &&
      source.useEbilling &&
      source.allowPaperlessEbilling &&
      source.pinGateEbilling &&
      source.displayType === displayType),

  displayTypeShowTermsOfUse: (_state, getters) => displayType =>
    getters.payableSources.length &&
    getters.payableSources.some(source =>
      source.useEbilling &&
      source.showTermsOfUse &&
      source.displayType === displayType),

  displayTypeTermsOfUseLink: (_state, getters) => displayType =>
    getters.payableSources.find(source =>
      source.useEbilling &&
      source.showTermsOfUse &&
      source.displayType === displayType)?.termsOfUseLink || '',

  displayTypeUsesUserVerification: (_state, getters) => displayType =>
    getters.payableSources.length &&
    getters.payableSources.some(source =>
      source.useUserVerification &&
      source.displayType === displayType),

  chatBotUrl: state => isProd()
    ? state.config?.chatBot?.chatBotUrl
    // in nonprod fallback to prod url for backwards compatibility
    : (state.config?.chatBot?.chatBotUrlNonprod || state.config?.chatBot?.chatBotUrl),

  // Returns true if the user has all scopes necessary to edit this site
  // Inefficient operation
  checkAdminScopeForSite: ({ adminScope }) => (siteConfig) => {
    // TODO: Remove support for PEx identifiers after PEX-15857
    if (!adminScope) {
      return false
    }
    let pexClientName, departments, clientName, siteName
    try {
      ; ({
        paymentExpress: { clientName: pexClientName, departments },
        client: clientName,
        site: siteName,
      } = siteConfig)
    }
    catch (error) {
      const message = 'siteConfig destructuring error: ' + error
      console.error(message)
      sentryException(new Error(message))
      return false
    }

    for (const scope of adminScope) {
      const lcScope = /^admin:gsg$/i.test(scope) ? scope : scope.toLowerCase()
      const [admin, client, departmentOrSite] = lcScope.split(':') || []
      // Non admin scope
      if (admin !== 'admin') {
        continue
      }
      // GSG admins have full access
      if (client === 'gsg') {
        return true
      }
      const isPexClient = client === pexClientName?.toLowerCase()
      const isPHClient = client === clientName?.toLowerCase()
      // Either a different client or this is a non-compliant scope; skip
      if ((!isPexClient && !isPHClient) || typeof departmentOrSite !== 'string') {
        continue
      }
      // Access to *all* of the client's sites
      if (departmentOrSite === '*') {
        return true
      }
      if (isPexClient) {
        // If they have any PEx department, they are allowed to
        // see this site
        if (departments.map((d) => d && d.toLowerCase()).includes(departmentOrSite.toLowerCase())) {
          return true
        }
      }
      // If this scope matches the PH client and site exactly then accept
      // it. Because this can't happen to a site where the PH client name
      // exactly matches the PEx client
      // It would be possible to inappropriately grant access to a site
      // provided:
      // - The PEx and PH identifiers are identical
      // - The the site requires additional scopes the user doesn't have
      //
      // To prevent this we keep this as an else if
      else if (isPHClient && departmentOrSite === siteName.toLowerCase()) {
        return true
      }
    }

    return false
  },

  // Use this to check permissions so and try to avoid
  // checkAdminScopeForSite when possible
  adminHasAccessToSite: state => (clientKey, siteKey) => {
    return state.clientSiteAccessList.some(({ client, site }) => client === clientKey && site === siteKey)
  },

  useCostLinkedPricing: state => {
    // If a launch darkly flag exists, respect that flag
    const flag = `ab-cost-linked-pricing.${state.config.client}-${state.config.site}`
    if (state.flags?.hasOwnProperty(flag)) {
      return state.flags[flag]
    }
    // Otherwise return the site setting value
    return state.config.paymentExpress?.useCostLinkedPricing
  },
}

const mutationDefinitions = {
  setGlobals (state, globals) {
    state.globals = globals
  },

  setConfig (state, config) {
    state.config = config
  },

  setAllConfigs (state, allConfigs) {
    state.allConfigs = allConfigs
  },

  setLDClient (state, ldClient) {
    state.ldClient = ldClient
  },

  mergeFlags (state, flags) {
    state.flags = { ...state.flags, ...flags }
  },

  setLoadConfigPromise (state, promise) {
    state.loadConfigPromise = promise
  },

  setLoadGlobalsPromise (state, promise) {
    state.loadConfigGlobalsPromise = promise
  },

  setClientList (state, newClients) {
    state.clientList = newClients.sort(sortByProp('name'))
  },

  addClientLocal (state, newClient) {
    state.clientList.push(newClient)
    state.clientList = state.clientList.sort(sortByProp('name'))
  },

  setNonProdJwt (state, nonProdJwt) {
    state.nonProdJwt = nonProdJwt
  },

  setProdJwt (state, prodJwt) {
    state.prodJwt = prodJwt
  },

  // Sets a flag that controls whether or not we will use the cached values
  // returned via GET requests to the config service or if we'll break the cache
  // and always get the most up-to-date configs.
  setCacheGETs (state, cacheGETs) {
    state.cacheGETs = Boolean(cacheGETs)
  },

  // Sets a flag that controls
  // whether we alert when nonprod settings that expect a client source
  // don't have a client source in production.
  // This is a site settings publishing bug,
  // so we only warn about it in site settings contexts.
  setSanityCheckClientLevelSettings (state, doValidate) {
    state.sanityCheckClientLevelSettings = Boolean(doValidate)
  },

  setLDMetadata (state, ldMetadata) {
    state.ldMetadata = ldMetadata
  },

  setClientMeta (state, clientMeta) {
    state.clientMeta = clientMeta
  },

  setSiteMeta (state, siteMeta) {
    state.siteMeta = siteMeta
  },

  setAdminScope (state, scopes) {
    scopes = scopes.map(scope => /^admin:gsg$/i.test(scope) ? scope : scope.toLowerCase())
    state.adminScope = scopes
  },

  setAdminUserJwt (state, remoteUserJwt) {
    state.adminUserJwt = remoteUserJwt
  },

  setClientSiteAccessList (state, clientSiteAccessList) {
    state.clientSiteAccessList = clientSiteAccessList
  },

  setClientSiteMasterList (state, clientSiteMasterList) {
    state.clientSiteMasterList = clientSiteMasterList
  },

  setMasterSitesPerClient (state, masterSitesPerClient) {
    state.masterSitesPerClient = masterSitesPerClient
  },

  setLogDiagnostics (state, logDiagnostics) {
    if (typeof logDiagnostics !== 'function') {
      throw new TypeError('logDiagnostics must be a function.')
    }
    state.logDiagnostics = logDiagnostics
  },

  /**
   * Reset the store's state to the initial state while maintaining reactivity. This is used in tests.
   */
  reset (state) {
    const s = new State()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
}

const actionDefinitions = {
  initialize ({ state, commit }, {
    logDiagnostics = state.logDiagnostics,
    nonProdJwt = state.nonProdJwt,
    prodJwt = state.prodJwt,
    cacheGETs = state.cacheGETs,
    sanityCheckClientLevelSettings = state.sanityCheckClientLevelSettings,
  }: InstallParams = {}) {
    commit('setLogDiagnostics', logDiagnostics)
    commit('setNonProdJwt', nonProdJwt)
    commit('setProdJwt', prodJwt)
    commit('setCacheGETs', cacheGETs)
    commit('setSanityCheckClientLevelSettings', sanityCheckClientLevelSettings)
  },
  // XXX: Some of the tests depend on mocking these. If you add a new one,
  // update them.
  // Loads global config service flags from <app>/data-pub/globals
  async loadConfigGlobals ({ state, dispatch, commit }) {
    const loadConfigGlobalsPromise = state.loadConfigGlobalsPromise || (async () => {
      let globals
      try {
        globals = (await dispatch('getConfigGlobals'))?.globals
      }
      catch (error) {
        globals = {}

        if (!isAxiosError(error)) {
          console.error(error)
          sentryException(error as Error)
          return
        }

        const message = `Could not get globals from config service. Error: "${error.message}"`
        console.error(message)
        sentryException(new Error(message))
        // Don't throw since the app should treat these as non-critical
      }

      commit('setGlobals', globals)
    })()

    commit('setLoadGlobalsPromise', loadConfigGlobalsPromise)
    await loadConfigGlobalsPromise
  },

  // Loads the config for the given client and site. It overrides client
  // configs with any site configs.
  async loadConfig ({ dispatch, commit, state }, { client, site }: { client?: string, site?: string }) {
    let config
    const loadConfigPromise = state.loadConfigPromise || (async () => {
      // Moved from old loadConfig helper in PH. Wonder if this should be
      // changed to throw and deps should catch...
      if (!client) {
        // Give up if client/site are missing. We can't load config or
        // initialize the router in that case.
        console.warn(`Can't initialize config without client (${client})`)
        return {}
      }
      try {
        let useClientOnlyUrl
        let defaultSiteUrl
        // getClientsAndSites has already grabbed meta data for sites and clients.
        if (Object.keys(state.clientMeta).length > 0) {
          useClientOnlyUrl = state.clientMeta?.[client]?.useClientOnlyUrl
          defaultSiteUrl = state.clientMeta?.[client]?.defaultSiteUrl
        }
        else {
          const clientSettings = await dispatch('getClientMetadata', {
            clientId: client,
          })

          useClientOnlyUrl = clientSettings[`/payhub/data-pub/meta/clients/${client}/useClientOnlyUrl`] || false
          defaultSiteUrl = clientSettings[`/payhub/data-pub/meta/clients/${client}/defaultSiteUrl`] || ''
        }

        // Don't bother checking site legitimacy yet
        // if we can't do anything about it
        if (defaultSiteUrl || useClientOnlyUrl) {
          // !siteExists implies either we don't have a site,
          // or we've misidentified a module as a site for a client-level URL
          // eg. "forms" in hillsborough/forms/deliquent-tax
          const siteExists = await dispatch('checkIfSiteExists', { site, client })
          if (!siteExists) {
            // If the site does not exist, the request resulting from the
            // dispatched 'checkIfSiteExists' will have expectedly errored. We
            // swallow this error, but some browsers will still write an error
            // message to the console. We don't want this error to confuse
            // devs investigating errors.
            console.info("Please ignore the 'Failed to load resource' 404 error from the config service. This is an expected 404 response that can only be hidden by modifying your browser devtool settings.")
            if (defaultSiteUrl) {
              site = defaultSiteUrl
            }
            if (useClientOnlyUrl) {
              site = client
            }
          }
        }

        config = await dispatch(!isProd() ? 'getNonProdSiteSettings' : 'getProdSiteSettings', {
          clientId: client,
          siteId: site,
        })

        config = formatSiteConfigFromService({
          siteConfigs: config,
          client,
          site,
          useClientOnlyUrl,
          defaultSiteUrl,
        })
        if (!config || isEmpty(config)) {
          // Site is blank, but must be included as this is not a
          // Client Only URL or Default Site URL client
          if (!useClientOnlyUrl && !defaultSiteUrl && !site) {
            throw new Error('Getting configs must include site with client')
          }
          // Client and site are either both included or both undefined
          else {
            throw new Error('No configs returned from service')
          }
        }
      }
      catch (error) {
        if (!isAxiosError(error)) {
          console.error(error)
          sentryException(error as Error)
          throw error
        }

        const message = `Could not get site settings from config service (client: ${client}, site: ${site}). Error: "${error.message}"`
        console.error(message)
        sentryException(new Error(message))
        throw error
      }

      // Configs have been fetched

      // Fetch flags from LD
      if (config?.payHub?.useLaunchDarkly && !Object.keys(state.flags).length) {
        try {
          await dispatch('loadFlags', { client, site })
        }
        catch (error) {
          const message = "Couldn't load LaunchDarkly flags (ignoring): " + error
          console.error(message)
          sentryException(new Error(message))
        }
      }

      if (state.flags) {
        config = overrideFlaggedConfigs({ flags: state.flags, config })
      }

      commit('setConfig', config)

      if (Object.keys(config).length !== 0) {
        await dispatch('loadConfigI18n')
      }

      EventBus.$emit('config.configChanged', config)
      commit('setLoadConfigPromise', null)
    })()

    commit('setLoadConfigPromise', loadConfigPromise)
    await loadConfigPromise
  },

  /**
   * Resets and loads all the configuration needed for admin modules, which cross
   * client/site boundaries. These are the minimum configs needed to
   * show lists of client/sites and determine which the current user is
   * allowed to use (see `computeClientSiteLists`).
   *
   * To add more configuration when using an admin module, see
   * `loadAdminModule()`
   */
  async loadAdminConfig ({ commit, state, dispatch, getters }): Promise<void> {
    const { clients: allClients, sites: allSites } = await dispatch('getClientsAndSites', {})
    if (!allClients) {
      return
    }

    const api = isProd() ? getters.prodApi : getters.nonProdApi
    const { data } = await api.getSettings({ module: 'paymentExpress' })
    const gotConfigs = objectifyConfigs(data)

    // This creates a list of all clients and sites with either of the
    // prod or non-prod settings populated from the API response.
    const nonProdSettings = isProd() ? {} : gotConfigs
    const prodSettings = isProd() ? gotConfigs : {}
    const clients = generateClientList({
      moduleList: state.moduleList,
      allClients,
      allSites,
      nonProdSettings,
      prodSettings,
    })
    commit('setClientList', clients)

    await dispatch('buildAllConfigs')
  },

  /**
   * Load config for the given module, optionally restricted to client,
   * site, and/or setting. This will enhance the config created by
   * `loadAdminConfig()`, so that must be called first.
   */
  async loadAdminModule ({ state, dispatch, getters }, { client = '*', site = '*', module, setting = '*' }: { client?: string, site?: string, module: string, setting?: string }): Promise<void> {
    // We are enhancing the configs stored in the clientList (populated
    // by `getClientsAndSites`), so this must exist already.
    const clientList = state.clientList
    if (!clientList || !Array.isArray(clientList) || !clientList.length) {
      throw new Error('Cannot load admin module config: clientList is not populated (did you call loadAdminConfig() first?)')
    }

    // Fetch the settings data into an object
    const api = isProd() ? getters.prodApi : getters.nonProdApi
    const { data } = await api.getSettings({ client, site, module, setting })
    const dataObject = objectifyConfigs(data)

    // Merge that object into the appropriate prod/nonprod settings of
    // the clientList
    for (const client of state.clientList) {
      for (const site of client.sites) {
        const existingSettings = isProd() ? site.production : site.nonProd
        const gotSettings = parseSiteSettings(
          state.moduleList,
          client.id,
          site.id,
          dataObject,
        )
        for (const moduleId of Object.keys(state.moduleList)) {
          const gotModuleSettings = gotSettings[moduleId]
          if (!gotModuleSettings) {
            continue
          }
          if (existingSettings[moduleId]?.settings) {
            existingSettings[moduleId].settings = {
              ...cloneDeep(existingSettings[moduleId].settings),
              ...gotModuleSettings.settings,
            }
          }
          else {
            existingSettings[moduleId] = gotModuleSettings
          }
        }
      }
    }

    // Rebuild the allConfigs data structure with the newly-integrated
    // data.
    await dispatch('buildAllConfigs')
  },

  // Create the "allConfigs" data structure by going through the
  // client list and picking out either the prod or non-prod settings
  // in the client list.
  buildAllConfigs ({ state, commit }): void {
    const allConfigs = {}
    // Load configs from config-service configs
    for (const client of state.clientList) {
      allConfigs[client.id] = allConfigs[client.id] || {}

      for (const site of client.sites) {
        let config = isProd() ? site.production : site.nonProd
        if (
          (isProd() && !site.inProd) ||
          (!isProd() && !site.inNonProd) ||
          !config || !Object.keys(config).length
        ) {
          // There are no configs stored for this site or it hasn't been fully
          // published to this environment
          continue
        }

        config = formatSiteConfigFromService({
          siteConfigs: config,
          client: client.id,
          site: site.id,
          useClientOnlyUrl: Boolean(client.useClientOnlyUrl),
        }) || {}

        if (!Object.keys(config).length) {
          const message = `loadAllConfigs: Could not parse configs from config service (client: ${client.id}, site: ${site.id}).`
          console.error(message)
          sentryException(new Error(message))
        }

        config.meta = {
          clientName: client.name,
          siteName: site.title,
        }

        allConfigs[client.id][site.id] = config
      }
    }

    commit('setAllConfigs', allConfigs)
  },

  // Loads and flattens all site configs across all clients, using the config
  // service list
  //
  // This doesn't call loadConfig to load each site separately from the config
  // service because here we can more optimally download ALL site configs in the
  // beginning.
  async loadAllConfigs ({ dispatch }) {
    // These populate functions will create the clientList data with the
    // sites filled-in with either nonprod or prod settings data.
    await dispatch(!isProd() ? 'populateOnlyNonProdData' : 'populateOnlyProdData', {})
    // This builds the allConfigs data from the clientList data.
    await dispatch('buildAllConfigs')
  },

  // TODO: Make optional by initting store with or w/o i18n
  // Loads additional translations that rely on config values
  async loadConfigI18n ({ state }) {
    const config = state.config

    if (Object.keys(config).length === 0) {
      return
    }

    const overrides: TranslatedTextObjects = {}

    for (const { feeStructure, itemCategory } of state.config.cart?.itemCategories || []) {
      overrides[`translated-fee-keys.${itemCategory.toLowerCase()}`] = feeStructure
    }

    if (config.schedPay?.meta?.enabled) {
      // eslint-disable-next-line camelcase
      overrides.recurrence_label = config.schedPay.recurrenceLabel
    }

    overrides['policy_agreement.card_policy'] = config?.eWallet?.cardPolicy
    overrides['policy_agreement.bank_policy'] = config?.eWallet?.bankPolicy
    overrides['policy_agreement.paypal_policy'] = config?.eWallet?.paypalPolicy

    // Should this check if it's enabled?
    if (config.help) {
      overrides['help.html'] = config.help.helpText
    }
    overrides['error.limitExceeded.desc'] = config?.cart?.limitExceededMessage
    overrides['checkout.return'] = config?.payHub?.returnHomeText
    // Overwrite default '7-10 business days' if there is a mailing time
    const configMailingTime = config?.delivery?.mailingTime
    const hasMailingTime = configMailingTime && configMailingTime.en && configMailingTime.es
    if (hasMailingTime) {
      overrides['delivery.mailing_time.period'] = configMailingTime
    }

    // Overwrite the mailing_time config with our custom instructions if we have the value.
    // Otherwise, default to useing delivery.mailing_time.period in conjunction with the default
    // delivery.mailing_time.default text
    const configMailingInstructions = config?.delivery?.mailingInstructions
    const hasMailingInstructions = configMailingInstructions && configMailingInstructions.en && configMailingInstructions.es
    if (hasMailingInstructions) {
      overrides['delivery.mailing_time.default'] = configMailingInstructions
    }

    overrides['footer.home'] = config?.payHub?.footerHomeText

    // Should this check if it's enabled?
    if (config.eWallet) {
      // Get feeName from eWallet or default to "convenience"
      // setup i18n linked message
      let feeName = `@:${config.eWallet.feeName || 'convenience'}`

      const flag = `fee-name.${state.config.client}-${state.config.site}`
      if (state.flags?.hasOwnProperty(flag)) {
        feeName = state.flags[flag]
      }

      overrides['fee.name'] = { en: feeName, es: feeName }
    }

    const filteredOverrides = Object.fromEntries(
      Object.entries(overrides)
        .filter((entry): entry is [string, NonNullable<TranslatedTextObject>] => {
          const [key, langMap] = entry

          return typeof key === 'string' &&
                 typeof langMap === 'object' &&
                 Boolean(langMap)
        })
        .map(([key, langMap]) => [
          key,
          {
            en: filterSpecialCharacters(langMap.en || ''),
            es: filterSpecialCharacters(langMap.es || ''),
          },
        ]),
    )

    addI18n(filteredOverrides, sentryException)
  },

  // Loads the LaunchDarkly client. This is a hack for PSC-8004
  // and should be moved or removed.
  async loadLDClient ({ state, commit, getters }) {
    const id = isProd() ? '5cbb2d789e851908187acbeb' : '5cbb2d789e851908187acbea'
    const uaParser = new UAParser()
    const { name, email } = state.ldMetadata || {}

    // Get Device type
    let device = typeof window !== 'undefined' ? uaParser.getDevice().type : ''
    device = (device === 'mobile' || device === 'tablet') ? device : 'desktop'

    // Ignoring typescript error because VueCookies is typed wrong. See:
    // https://github.com/cmp-cc/vue-cookies/issues/76
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ip = VueCookies.get('client_ip')

    const userContextData = {
      kind: 'user',
      key: getters.sessionId,
      email,
      // TODO: We do this in a few places. It would be nice to abstract out for
      // consistency.
      ...(ip ? { ip } : {}),

      custom: {
        browser: uaParser.getResult().browser.name,
        device,
        sessionId: getters.sessionId,
        name,
      },
    }

    try {
      const ldClient = LDClient.initialize(id, userContextData)
      await ldClient.waitForInitialization()
      commit('setLDClient', ldClient)
    }
    catch (error) {
      if (!(error instanceof Error)) {
        return sentryException(new Error('Unknown error occurred while initializing LaunchDarkly: ' + String(error)))
      }

      if (
        error.name === 'LaunchDarklyFlagFetchError' &&
        error.message.match(/network error|Failed to execute 'send' on 'XMLHttpRequest'/)
      ) {
        // User navigated away before the LD request could complete.
        return
      }

      sentryException(error)
    }
  },

  // Loads the LaunchDarkly flags and logs them to Kibana. This accepts
  // the client and site so we can log those to Kibana as well.
  async loadFlags ({ commit, state, dispatch }, { client, site }: {client?: string, site?: string} = {}) {
    await dispatch('loadLDClient')
    const ldClient = state.ldClient
    commit('mergeFlags', ldClient.allFlags())
    EventBus.$emit('config.flagsChanged', state.flags)

    if (client && site) {
      state.logDiagnostics({
        'feature-flags': state.flags,
        client,
        site,
      })
    }

    ldClient.on('change', (settings: LDFlagChangeset) => {
      // LaunchDarkly sends an object like this for each setting that
      // has changed:
      //
      //   name: { current: "new value", old: "old value" }
      //
      // Before merging, we replace that with:
      //
      //   name: "new value"
      //
      Object.entries(settings).forEach(([key, value]) => {
        settings[key] = value.current
      })
      commit('mergeFlags', settings)
      EventBus.$emit('config.flagsChanged', state.flags)
    })
    // If we are using Google Tag Manager, push AB test flags
    // to the GTM data layer
    if (window.dataLayer) {
      const regexp = new RegExp(`^ab-.*\\.${client}-${site}$`)
      const abflags : string[] = []
      for (const flag in state.flags) {
        if (state.flags[flag] && regexp.test(flag)) {
          abflags.push(flag)
        }
      }

      window.dataLayer.push({ 'event': 'A/B test flags',
        'A/B test flags': abflags,
      })
    }

    return state.flags
  },

  // Downloads the client's meta settings and returns it
  async getClientMetadata ({ getters }, { clientId }) {
    let data
    try {
      ({ data } = await getters.prodApi.getClientMetadata({ clientId }) || {})
      return data
    }
    catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return {}
      }

      throw error
    }
  },

  // Downloads the client's site's meta settings and returns it
  async getSiteMetadata ({ getters }, { clientId, siteId }) {
    let data
    try {
      ({ data } = await getters.prodApi.getSiteMetadata({ clientId, siteId }) || {})
      return data
    }
    catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return {}
      }

      throw error
    }
  },

  async getProdSiteSettings ({ state, getters }, { clientId, siteId, parse = true }) {
    let data
    try {
      ; ({ data } = await getters.prodApi.getSiteSettings({ clientId, siteId }) || {})
      if (!data) {
        return {}
      }
    }
    catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return {}
      }

      throw error
    }

    const moduleSettings = objectifyConfigs(data)

    return parse ? parseSiteSettings(state.moduleList, clientId, siteId, moduleSettings) : moduleSettings
  },

  async getNonProdSiteSettings ({ state, getters }, { clientId, siteId, parse = true }) {
    let data
    try {
      ; ({ data } = await getters.nonProdApi.getSiteSettings({ clientId, siteId }) || {})
    }
    catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return {}
      }

      throw error
    }
    // XXX: Loop through all possible modules and see if there are any the site
    // knows nothing about (it has no meta setting for them). If there are, it
    // means the module has been added since the site was created. For each new
    // module, set meta.enabled to false and save the meta setting back to the
    // config service. If a module has been removed, do nothing (we don't want
    // an accidental removal of a json file to delete all relevant data from the
    // config service).

    const moduleSettings = objectifyConfigs(data)

    return parse ? parseSiteSettings(state.moduleList, clientId, siteId, moduleSettings) : moduleSettings
  },

  async getConfigGlobals ({ getters }) {
    let data
    try {
      ;({ data } = await getters[!isProd() ? 'nonProdApi' : 'prodApi'].getGlobalFlags() || {})
      if (!data) {
        return {}
      }
    }
    catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return {}
      }
    }

    return objectifyConfigs(data)
  },

  // Downloads and populates only prod site data. This includes settings saved
  // on the client level.
  async populateOnlyProdData ({ state, commit, dispatch }) {
    const { clients: allClients, sites: allSites } = await dispatch('getClientsAndSites')
    if (!allClients) {
      return
    }

    const clients = generateClientList({
      moduleList: state.moduleList,
      allClients,
      allSites,
      prodSettings: await dispatch('getAllProdSettings'),
    })

    commit('setClientList', clients)
  },

  // Downloads and populates only non-prod site data.
  //
  // This does NOT include settings saved on the client level because
  // client-level settings are "environment-less." Changing a client-level
  // setting does not immediately affect the sites that inherit that setting;
  // you have to use the Site Settings UI to update the inherited sites one by
  // one. Because the client settings are environment-less, we treat them like
  // the client and site metadata and save them to prod only.
  async populateOnlyNonProdData ({ state, commit, dispatch }) {
    const { clients: allClients, sites: allSites } = await dispatch('getClientsAndSites')
    if (!allClients) {
      return
    }

    const clients = generateClientList({
      moduleList: state.moduleList,
      allClients,
      allSites,
      nonProdSettings: await dispatch('getAllNonProdSettings'),
    })

    commit('setClientList', clients)
  },

  // Gets the data under /meta, including the list of clients and sites
  async getClientsAndSites ({ getters, commit }) {
    let data
    try {
      ; ({ data } = await getters.prodApi.getMeta() || {})
      if (!data) {
        return {}
      }
    }
    catch (error) {
      if (isAxiosError(error) && (!error.response || error.response.status !== 404)) {
        throw error
      }
    }

    const { clients = {}, sites = {} } = objectifyConfigs(data).meta || {}
    commit('setClientMeta', clients)
    commit('setSiteMeta', sites)

    return {
      clients,
      sites,
    }
  },

  // Gets Client and Site metadata for Site Settings admin dashboard
  async populateAllMetadata ({ state, commit, dispatch }) {
    const { clients: allClients, sites: allSites } = await dispatch('getClientsAndSites', {})
    if (!allClients) {
      return
    }

    const clients = generateClientList({
      moduleList: state.moduleList,
      allClients,
      allSites,
    })

    commit('setClientList', clients)
  },

  // Action modeled after populateAllData but for only one client
  async populateClientSettings ({ state, commit, dispatch }, clientId) {
    const { clients: allClients, sites: allSites } = await dispatch('getClientsAndSites', {})

    if (!allClients) {
      return
    }

    const clientList = generateClientList({
      moduleList: state.moduleList,
      allClients,
      allSites,
    })

    if (!clientId) {
      commit('setClientList', clientList)
      return
    }

    const client = clientList.find(({ id }) => id === clientId)

    // Settings objects have site settings nested in a site name, client name, and
    // "settings" property
    const [nonProdSiteSettings, prodSiteSettings, prodClientSettings] = await Promise.all([
      // Client settings are stored in production because they have no concept of
      // environment. Only _site_ settings are published to environments.
      // Settings are nested in a client name and "settings" property
      Promise.all(
        (client?.sites || [])
          .filter(({ inNonProd }) => inNonProd)
          .map(clientSite => dispatch('getNonProdSiteSettings', {
            clientId,
            siteId: clientSite.id,
            parse: false,
          })),
      ),
      Promise.all(
        (client?.sites || [])
          .filter(({ inProd }) => inProd)
          .map(clientSite => dispatch('getProdSiteSettings', {
            clientId,
            siteId: clientSite.id,
            parse: false,
          })),
      ),
      dispatch('getClientSettings', clientId),
    ])

    const [
      nonProdSettings,
      prodSettings,
    ] = [
      nonProdSiteSettings,
      [prodClientSettings, ...prodSiteSettings],
    ]
      .map(settings =>
        settings
          .reduce(({ [clientId]: sum }, { settings: { [clientId]: clientSettings } = {} }) => ({
            [clientId]: {
              ...sum,
              ...clientSettings,
            },
          }), {}),
      )
      .map(settings => ({ settings }))

    const clients = generateClientList({
      moduleList: state.moduleList,
      allClients,
      allSites,
      nonProdSettings,
      prodSettings,
      sanityCheckClientLevelSettings: state.sanityCheckClientLevelSettings,
    })

    commit('setClientList', clients)
  },

  // Only used by populateClientSettings
  async getClientSettings ({ getters }, clientId) {
    let res
    try {
      res = await getters.prodApi.getClientSettings({ clientId })
    }
    catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return false
      }
      throw error
    }

    const moduleSettings = objectifyConfigs(res.data)

    return moduleSettings
  },

  getAllNonProdSettings ({ getters, dispatch }) {
    return dispatch('getAllSettingsFromAPI', getters.nonProdApi)
  },

  getAllProdSettings ({ getters, dispatch }) {
    return dispatch('getAllSettingsFromAPI', getters.prodApi)
  },

  async getAllSettingsFromAPI (_store, api) {
    let data
    try {
      ; ({ data } = await api.getAllSettings() || {})
    }
    catch (error) {
      if (isAxiosError(error) && (!error.response || error.response.status !== 404)) {
        throw error
      }
    }

    return objectifyConfigs(data)
  },

  // Gets a single site setting for either non-prod or prod
  async getSiteSetting (_store, {
    api,
    site,
    module,
    setting,
  }) {
    return getSiteSetting(
      api,
      site.clientId,
      site.id,
      module,
      setting,
    )
  },

  getNonProdSiteSetting ({ getters }, params) {
    return getSiteSetting(
      getters.nonProdApi,
      params.site.clientId,
      params.site.id,
      params.module,
      params.setting,
    )
  },

  getProdSiteSetting ({ getters }, params) {
    return getSiteSetting(
      getters.prodApi,
      params.site.clientId,
      params.site.id,
      params.module,
      params.setting,
    )
  },

  // checkIfSiteExists populates client/site metadata (if necessary)
  // and returns a boolean indicating whether the given site exists
  async checkIfSiteExists ({ dispatch, state }, { client, site }) {
    if (!client || !site) {
      return false
    }

    if (!isEmpty(state.siteMeta)) {
      return state.siteMeta?.[client]?.[site]
    }

    const siteSettings = await dispatch('getSiteMetadata', {
      clientId: client,
      siteId: site,
    })

    return siteSettings[`/payhub/data-pub/meta/sites/${client}/${site}/id`]
  },

  /**
   * Dispatch this to compute information necessary for the admin
   * panels. Assumes that `loadAdminConfig` and `setAdminScope` have been run.
   */
  computeClientSiteLists: ({ state, getters, commit }) => {
    const defaultReduceValue: [
      {client: string, site: string}[],
      {client: string, site: string}[],
      {[key: string]: string[]}
    ] = [[], [], {}]

    const [
      clientSiteAccessList,
      clientSiteMasterList,
      masterSitesPerClient,
    ] = Object.keys(state.allConfigs)
      .reduce((data, client) => {
        const clientData = state.allConfigs[client]
        const keys = Object.keys(clientData)
        const clientSites = keys.map(site => ({ client, site }))

        data[0]
          .push(...clientSites
            .filter(({ site }) =>
              getters.checkAdminScopeForSite(clientData[site])))
        data[1].push(...clientSites)
        data[2][client] = keys

        return data
      }, defaultReduceValue)

    commit('setClientSiteAccessList', clientSiteAccessList)
    commit('setClientSiteMasterList', clientSiteMasterList)
    commit('setMasterSitesPerClient', masterSitesPerClient)
  },
}

// Apparently classes are handled differently so no typeof here 🤷‍♂️
export type ConfigStateKey = keyof State
export type ConfigState = (State)[ConfigStateKey]
export type ConfigGetterKey = keyof typeof getterDefinitions
export type ConfigGetter = (typeof getterDefinitions)[ConfigGetterKey]
export type ConfigMutationKey = keyof typeof mutationDefinitions
export type ConfigMutation = (typeof mutationDefinitions)[ConfigMutationKey]
export type ConfigActionKey = keyof typeof actionDefinitions
export type ConfigAction = (typeof actionDefinitions)[ConfigActionKey]

export default new Vuex.Store({
  state: new State(),
  getters: getterDefinitions,
  mutations: mutationDefinitions,
  actions: actionDefinitions,
})
