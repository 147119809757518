// Maps a list of file type categories to human-friendly descriptions and
// accepted "types" (HTML file input `accept` values). This is used by
// FilePicker.vue to limit the types of files accepted by the component.
export const fileTypeMap = {
  'image': {
    types: [
      'image/*',
    ],
    description: 'image',
  },
  'pdf': {
    types: [
      'application/pdf',
      '.pdf',
    ],
    description: 'pdf',
  },
  'document': {
    types: [
      'application/pdf',
      '.pdf',
      '.doc',
      '.docx',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    description: 'pdf, word',
  },
  'csv': {
    types: [
      'text/csv',
      '.csv',
    ],
    description: 'csv',
  },
}

export type supportedFileType = keyof typeof fileTypeMap
